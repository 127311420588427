// src/Sidebar.tsx
import React from 'react';
import { Nav } from 'react-bootstrap';
import { FaHome, FaMoneyCheckAlt } from 'react-icons/fa'; // Importamos iconos
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { TransactionPage } from '../pages/transaction.page';

interface SidebarProps {
  onLogout: () => void;  // Recibe la función de logout como prop
}

const Sidebar: React.FC<SidebarProps> = ({ onLogout }) => {
  const handleLogoutClick = () => {
    const confirmed = window.confirm('Are you sure you want to log out?');
    if (confirmed) {
      onLogout();
    }
  };
  return (
    <Router>
      <div className="d-flex">
        <div className="d-flex flex-column bg-dark text-white p-3 vh-100">
          <h4 className="text-center">Dashboard</h4>
          <Nav className="flex-column flex-grow-1">
            <Nav.Item className="my-2">
              <Link to="/" className="text-white nav-link">
                <FaHome className="me-2" /> Home
              </Link>
            </Nav.Item>
            <Nav.Item className="my-2">
              <Link to="/transactions" className="text-white nav-link">
                <FaMoneyCheckAlt className="me-2" /> Transactions
              </Link>
            </Nav.Item>
          </Nav>

          <div className="mt-auto">
            <button className="btn btn-danger w-100" onClick={handleLogoutClick}>Logout</button>
          </div>
        </div>

        <div className="flex-grow-1 p-4">
          <Routes>
            <Route path="/" element={<h1>Home Page</h1>} />
            <Route path="/transactions" element={<TransactionPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Sidebar;