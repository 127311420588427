// src/authService.ts
export async function loginService(user: string, password: string): Promise<string | null> {
    try {
        // const response = await fetch('https://localhost:3000/api/login', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ username: user, password: password }),
        // });

        // if (!response.ok) {
        //     throw new Error('Authentication error');
        // }

        if(user!=='admin' || password!=='admin'){
            throw new Error('Authentication error');
        }

        const data = {
            token: 'abc123'
        }
        //const data = await response.json();
        console.log(data);
        
        return data.token;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}